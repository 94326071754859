* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.login-container {
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-form-container {
  width: 300px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(5px);
  overflow: hidden;
  border-radius: 8px;
}

.login-form-header {
  color: #fff;
  background-color: #6f55a1;
  padding: 10px;
  text-align: center;
  border-radius: 8px 8px 0 0;
}

.login-form-content {
  padding: 20px;
  height: calc(100% - 40px);
  overflow-y: auto;
}

.logo {
  width: 250px; /* Ajusta el tamaño del logo según sea necesario */
  margin-bottom: 10px;
}

.login-btn {
  background-color: #6441a4;
  color: #fff;
  border: none;
  padding: 10px;
  cursor: pointer;
  margin: 5px;
  width: 250px;
}

.login-btn:hover {
  background-color: #4c2a80; /* Color de fondo al pasar el ratón sobre el botón */
}

.login-btn img {
  width: 25px; /* Ajusta el tamaño del icono de Twitch según sea necesario */
  margin-right: 10px;
}
