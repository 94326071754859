.icon {
  vertical-align: middle;
  line-height: 1px;
  font-size: 20px;
}
.icon_header {
  vertical-align: middle;
  line-height: 1px;
  font-size: 26px;
}
.icon,
.icon_header {
  margin-right: 5px;
}

/* Header  */
.header {
  grid-area: header;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px 0 30px;
}

.header-left img {
  width: 100px;
  margin: 10px;
}

.menu-icon {
  display: none;
}
/* End Header  */

/* Dashboard */
.main-container {
  grid-area: main;
  overflow-y: auto;
  padding: 20px 20px;
  color: rgba(255, 255, 255, 0.95);
  margin: 0px 60px;
}

.main-title {
  display: flex;
  justify-content: space-between;
  font-size: 25px;
}

.main-cards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin: 15px 0;
}

.card {
  display: flex;
  flex-direction: column;
  padding: 8px 15px;
  border-radius: 5px;
  background-color: #191026 !important;
  color: #fff !important;
}

.alert-settings {
  width: 750px;
}
  
.card-inner-title {
    display: grid;
    grid-template-columns: 3fr 3fr;
    gap: 16px;
    align-items: center;
    padding: 20px 0px;
}

.card-inner-title .save{
    justify-self: end; 
    display: flex;
}


.card-inner {
    display: grid;
    grid-template-columns: 0.2fr 1fr 2fr 3fr;
    gap: 16px;
    align-items: center;
    padding: 20px;
}


.card-inner .icon {
  justify-self: start;
}

.card-inner span {
  justify-self: start;
}

.card-inner .switch-container {
    justify-self: end; 
    display: flex;
    align-items: center;
}

.minimum-container {
    justify-self: end; 
    display: flex;
}

.icon {
    font-size: 25px;
    margin-left: 10px;
}

.card span{
    font-size: 14px;
}  
/* End Dashboard */

/* Switch toggle */
.react-switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.react-switch-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 60px;
  height: 22px;
  background: grey;
  border-radius: 100px;
  position: relative;
  transition: background-color 0.2s;
}

.react-switch-label .react-switch-button {
  content: '';
  position: absolute;
  left: 2px;
  width: 25px;
  height: 25px;
  border-radius: 45px;
  transition: 0.2s;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.react-switch-checkbox:checked + .react-switch-label .react-switch-button {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.react-switch-label:active .react-switch-button {
  width: 60px;
}

.switch-status {
  margin-left: 8px;
}
/* End Switch toggle*/

/* Check */
.switch-container {
  display: flex;
  align-items: center;
}

.checkbox {
  display: none;
}

.checkbox-label {
  display: inline-block;
  margin-left: 12px;
  width: 20px;
  height: 20px;
  border-radius: 6px;
  border: 1px solid #ccc;
  cursor: pointer;
  position: relative;
}

.checkbox:checked + .checkbox-label {
  background-color: #7834d8;
}

.checkbox-unavailable {
  border: 1px solid #ff0000;
}

.checkboxIcon {
  font-size: 25px;
  margin-right: 15px;
  cursor: pointer;
}

.check-content {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 4px;
  padding: 10px;
  max-width: 100%;
  box-sizing: border-box;
}

.check-inner {
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

/* End Check */

/* Medium <= 992px */

@media screen and (max-width: 992px) {
  .grid-container {
    grid-template-columns: 1fr;
    grid-template-rows: 0.2fr 3fr;
    grid-template-areas:
      'header'
      'main';
  }

  #sidebar {
    display: none;
  }

  .menu-icon {
    display: inline;
  }

  .sidebar-title > span {
    display: inline;
  }
}
/* Small <= 768px */

@media screen and (max-width: 768px) {
  .main-cards {
    grid-template-columns: 1fr;
    gap: 10px;
    margin-bottom: 0;
  }

  .main-container {
    margin: 0px;
  }

  .card {
    width: auto;
  }

  .charts {
    grid-template-columns: 1fr;
    margin-top: 30px;
  }
}

/* Extra Small <= 576px */

@media screen and (max-width: 576px) {
  .hedaer-left {
    display: none;
  }
}
